const SideBar = ({closeSideBar}) => {
  return (
    <>
      <nav className="w3-sidebar w3-bar-block w3-black w3-card w3-animate-left w3-hide-medium w3-hide-large" style={{display: "none"}} id="mySidebar">
        <a href="#" onClick={closeSideBar} className="w3-bar-item w3-button w3-large w3-padding-16">Close ×</a>
        <a href="#about" onClick={closeSideBar} className="w3-bar-item w3-button">ABOUT</a>
        <a href="#team" onClick={closeSideBar} className="w3-bar-item w3-button">TEAM</a>
        <a href="#work" onClick={closeSideBar} className="w3-bar-item w3-button">WORK</a>
        <a href="#pricing" onClick={closeSideBar} className="w3-bar-item w3-button">PRICING</a>
        <a href="#contact" onClick={closeSideBar} className="w3-bar-item w3-button">CONTACT</a>
      </nav>
    </>
  )
}

export default SideBar
