import React from 'react'

const Banner = ({language}) => {

    
  const leftCardEN = "Tips for maintaining olive wood";
  const leftCardDE = "Tipps zur Pflege von Olivenholz";

  
  const content1EN = " Regelmäßig einölen, um das Holz zu schützen."
  const content2EN = " Nicht in Wasser einweichen oder in die Spülmaschine geben."
  const content3EN = " Mit einem feuchten Tuch abwischen und trocken reiben."
  const content4EN = " Vermeiden Sie scharfe Reinigungsmittel und Scheuermittel."
  const content5EN = " Bei Bedarf sanft abschleifen und erneut einölen."
  const content1DE = " Regelmäßig einölen, um das Holz zu schützen."
  const content2DE = " Nicht in Wasser einweichen oder in die Spülmaschine geben."
  const content3DE = " Mit einem feuchten Tuch abwischen und trocken reiben."
  const content4DE = " Vermeiden Sie scharfe Reinigungsmittel und Scheuermittel."
  const content5DE = " Bei Bedarf sanft abschleifen und erneut einölen."

  return (
    <div className="banner-container">
        <div className="banner-flex">
            <div className="left-half">
                <div className="left-half-content">
                    <p className="tips-header">{language === "en" ? leftCardEN : leftCardDE}</p>
                    <div>
                        <div className='hack-div'>
                            <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                        </div>
                        <div className='hack-div2'>
                            <br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                        </div>
                        <img className='olive-dot' src='https://www.threeleaves.org/images/olive_dot2.png'></img><p className="tips-content">{language === "en" ? content1EN : content1DE}</p>
                        <br></br>
                        <img className='olive-dot' src='https://www.threeleaves.org/images/olive_dot2.png'></img><p className="tips-content">{language === "en" ? content2EN : content2DE}</p>
                        <br></br>
                        <img className='olive-dot' src='https://www.threeleaves.org/images/olive_dot2.png'></img><p className="tips-content">{language === "en" ? content3EN : content3DE}</p>
                        <br></br>
                        <img className='olive-dot' src='https://www.threeleaves.org/images/olive_dot2.png'></img><p className="tips-content">{language === "en" ? content4EN : content4DE}</p>
                        <br></br>
                        <img className='olive-dot' src='https://www.threeleaves.org/images/olive_dot2.png'></img><p className="tips-content">{language === "en" ? content5EN : content5DE}</p>
                    </div>
                </div>
            </div>
             <div className="right-half">

            </div>
        </div>
    </div>
  )
}

export default Banner
