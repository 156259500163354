import { useState } from "react";
import {useRef} from 'react';
import Product from "./Product";

const Work = ({language, setSlideImages}) => {

  const titleEN = "PRODUCTS";
  const titleDE = "PRODUKTE";

  const subtitleEN = "Quality ensured.";
  const subtitleDE = "Qualität gewährleistet.";

  const catEN = " Categories";
  const catDE = " Kategorien";
  
  const cardHolderEN = " Card Holder / Picture Holder";
  const cardHolderDE = " Kartenhalter / Fotohalter";

  const trivetEN = " Trivet";
  const trivetDE = " Untersetzer";

  const boardsEN = " Breakfast Boards";
  const boardsDE = " Brettchen";

  const woodEN = " Unprocessed Wood";
  const woodDE = " Unbehandeltes Holz";

  const otherEN = " Partner Products";
  const otherDE = " Partnerprodukte";

  const showAllEN = " Show all";
  const showAllDE = " Zeige alles";

  const ref = useRef(null);

  const [animating, setAnimating] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]);
  const toggleAnimating = (index) => {
    setAnimating(prevState => prevState.map((item, idx) => idx === index ? !item : item))
  };

  const flipCard = (e, cardNumber, flipIn) => {

    if (e.target instanceof HTMLButtonElement || e.target instanceof HTMLAnchorElement || e.target instanceof HTMLSpanElement)
    {
        return;
    } 

    const card = document.getElementById("card" + cardNumber + "-1");
    const card2 = document.getElementById("card" + cardNumber + "-2");
    const flipSpeedMS = 400;

    if (animating[cardNumber - 1]) {
        return;
    }
    
    toggleAnimating(cardNumber - 1);
    window.setTimeout ( function() {  
      toggleAnimating(cardNumber - 1);
      }, flipSpeedMS);

    if(flipIn) {
        card.classList = "flip-style w3-card top-card"
        card2.classList = "flip-style2 w3-card bottom-card"
        window.setTimeout ( function() {  
            card.classList = "flip-style w3-card bottom-card"
            card.style.opacity = "0%";
            card2.classList = "flip-style2 w3-card top-card"   
            card2.style.opacity = "99%";  
          }, flipSpeedMS / 2);
        window.setTimeout ( function() {   
            card.classList = "w3-card bottom-card"
            card2.classList = "w3-card top-card" 
          }, flipSpeedMS);
    } else {
        card.classList = "flip-style2 w3-card top-card"
        card2.classList = "flip-style w3-card bottom-card"
        window.setTimeout ( function() {  
            card.classList = "flip-style2 w3-card bottom-card"
            card.style.opacity = "99%";
            card2.classList = "flip-style w3-card top-card"   
            card2.style.opacity = "0%"; 
          }, flipSpeedMS / 2);
        window.setTimeout ( function() {   
            card.classList = "w3-card top-card"
            card2.classList = "w3-card bottom-card" 
          }, flipSpeedMS);
    }
}

const initialVisibleAreas = {
  'cardHolder': true,
  'trivet': true,
  'boards': true,
  'wood': true,
  'other': true
};

const [visibleAreas, setVisibleAreas] = useState(initialVisibleAreas);

const showCategory = (category) => {

  setVisibleAreas({  
    'cardHolder': category === 'cardHolder' ? true : false,
    'trivet': category === 'trivet' ? true : false,
    'boards': category === 'boards' ? true : false,
    'wood': category === 'wood' ? true : false,
    'other': category === 'other' ? true : false
  })

  for (let i = 0; i < 6; i++) {
    document.getElementById('cat-list').getElementsByTagName('span')[i].style.fontWeight = "normal";
  }

  switch (category) {
    case 'cardHolder':
      document.getElementById('cat-list').getElementsByTagName('span')[0].style.fontWeight = "bold";
    break;
    case 'trivet':
      document.getElementById('cat-list').getElementsByTagName('span')[1].style.fontWeight = "bold";
    break;
    case 'boards':
      document.getElementById('cat-list').getElementsByTagName('span')[2].style.fontWeight = "bold";
    break;
    case 'wood':
      document.getElementById('cat-list').getElementsByTagName('span')[3].style.fontWeight = "bold";
    break;
    case 'other':
      document.getElementById('cat-list').getElementsByTagName('span')[4].style.fontWeight = "bold";
    break;
    default:
      break;
  }

  window.setTimeout ( function() {   
    ref.current.checked = false;
  }, 100);
}

const showAllCategories = () => {

  const allTrue = Object.assign(
    ...Object.keys(visibleAreas).map((key) => ({ [key]: true }))
  );

  setVisibleAreas(allTrue);

  for (let i = 0; i < 6; i++) {
    document.getElementById('cat-list').getElementsByTagName('span')[i].style.fontWeight = "normal";
  }
  document.getElementById('cat-list').getElementsByTagName('span')[5].style.fontWeight = "bold";
  
  window.setTimeout ( function() {   
    ref.current.checked = false;
  }, 100);
}

const hideCatBar = (e) => {
  if (ref.current.checked && e.target instanceof HTMLDivElement) {
    ref.current.checked = false;
  }
  //console.log('✅ Checkbox is checked');
  //console.log('⛔️ Checkbox is NOT checked');
}

  return (
        <div className="w3-container2" style={{padding: "128px 16px"}} id="work" onClick={(e) => hideCatBar(e)}>
          <h1 className="w3-center">{language === "en" ? titleEN : titleDE}</h1>

          <div id="product-wrapper" style={{paddingBottom: "6px"}}>
            <nav id="category-nav">

            <label htmlFor="touch"><span className="category-dropdown no-highlight"> {language === "en" ? catEN : catDE}</span></label>               
            <input ref={ref} type="checkbox" id="touch" />         
            

            <ul id="cat-list" className="slide no-highlight">
              <li style={{cursor: "pointer"}} onClick={() => showCategory('cardHolder')}><span>{language === "en" ? cardHolderEN : cardHolderDE}</span></li>
              <li style={{cursor: "pointer"}} onClick={() => showCategory('trivet')}><span>{language === "en" ? trivetEN : trivetDE}</span></li>
              <li style={{cursor: "pointer"}} onClick={() => showCategory('boards')}><span>{language === "en" ? boardsEN : boardsDE}</span></li>
              <li style={{cursor: "pointer"}} onClick={() => showCategory('wood')}><span>{language === "en" ? woodEN : woodDE}</span></li>
              <li style={{cursor: "pointer"}} onClick={() => showCategory('other')}><span>{language === "en" ? otherEN : otherDE}</span></li>
              <li style={{cursor: "pointer"}} onClick={() => showAllCategories()}><span style={{fontWeight: "bold"}}>{language === "en" ? showAllEN : showAllDE}</span></li>
            </ul>
            </nav> 

            <div className="product-container">

                <Product 
                language={language}
                idNumber={1} 
                title={"Handbearbeitete Olivenholz Fotohalter 2er Set"} 
                imageSrc={["https://www.threeleaves.org/images/Handbearbeitete%20Olivenholz%20Fotohalter%202er%20Set/Foltohalter%202er%20box.jpg", "https://www.threeleaves.org/images/Handbearbeitete%20Olivenholz%20Fotohalter%202er%20Set/Foltohalter%202er.jpg" ]}
                contentText={["Unser handgefertigter Hochzeitskartenhalter aus Olivenholz ist ein einzigartiges und elegantes Accessoire für Ihre Hochzeitsfeier. Jeder Halter wird von uns aus hochwertigem Olivenholz gefertigt und ist somit ein Unikat.", "Das natürliche Olivenholz gibt diesem Halter einen rustikalen Charme und macht ihn zum perfekten Ergänzungselement für jede Hochzeitsdekoration. Unser Hochzeitskartenhalter aus Olivenholz ist als praktisch für Namenkarten und kann als Andenken den Gästen mitgegeben werden. Er kann leicht mit einem feuchten Tuch gereinigt werden und behält auch nach vielen Jahren seinen natürlichen Glanz und seine Schönheit.", "Wir sind uns sicher, dass dieser handgefertigte Hochzeitskartenhalter aus Olivenholz ein Highlight auf Ihrer Hochzeitsfeier sein wird und auch noch lange danach eine schöne Erinnerung an Ihren besonderen Tag darstellt."]} 
                measure={"ca. 7cm"}
                price={"14.99 €"} 
                flipCard={flipCard}
                setSlideImages={setSlideImages}
                productURL={"https://www.etsy.com/de-en/listing/1437291153/unique-olive-wood-card-holder-photo?variation0=4122395382"}
                show={visibleAreas['cardHolder']} />

                <Product 
                language={language}
                idNumber={2} 
                title={"Handbearbeitete Olivenholz Fotohalter 3er Set"} 
                imageSrc={["https://www.threeleaves.org/images/Handbearbeitete%20Olivenholz%20Fotohalter%203er%20Set/2er%20oder%203er.jpg", "https://www.threeleaves.org/images/Handbearbeitete%20Olivenholz%20Fotohalter%203er%20Set/3er%20schachtel.jpg", "https://www.threeleaves.org/images/Handbearbeitete%20Olivenholz%20Fotohalter%203er%20Set/3er.jpg" ]}
                contentText={["Unser Foto- oder Kartenhalter aus Olivenholz sind ein wunderschönes und praktisches Accessoire für Zuhause oder das Büro. Das natürliche Olivenholz gibt jedem Halter ein einzigartiges und rustikales Aussehen. Der Halter besteht aus einem Olivenholzstück, in dem Schlitze eingearbeitet sind, um Fotos oder Karten aufzustellen.", "Die Schlitze sind breit genug, um Fotos in verschiedenen Größen zu halten und bieten dabei eine stabile Unterstützung. Der Fotohalter ist handgefertigt und wurde sorgfältig poliert, um eine glatte und glänzende Oberfläche zu erhalten. Es ist eine großartige Möglichkeit, Erinnerungen und Fotos zu präsentieren und gleichzeitig ein Stück natürlicher Schönheit in Ihr Zuhause oder Büro zu bringen.", "Dies kann auch für Hochzeiten als Namenschildhalter verwendet werden und ist ein wunderbares Andenken!"]} 
                measure={"27cm"}
                price={"14.99 €"} 
                flipCard={flipCard}
                setSlideImages={setSlideImages}
                productURL={"https://www.etsy.com/de-en/listing/1437291153/unique-olive-wood-card-holder-photo?variation0=4122395388"}
                show={visibleAreas['cardHolder']} />

                <Product 
                language={language}
                idNumber={3} 
                title={"Handbearbeitete Olivenholz Fotohalter 4er Set"} 
                imageSrc={["https://www.threeleaves.org/images/Handbearbeitete%20Olivenholz%20Fotohalter%204er%20Set/4er_.jpg", "https://www.threeleaves.org/images/Handbearbeitete%20Olivenholz%20Fotohalter%204er%20Set/Foltohalter%202er%20box.jpg", "https://www.threeleaves.org/images/Handbearbeitete%20Olivenholz%20Fotohalter%204er%20Set/Foltohalter%202er.jpg" ]}
                contentText={["Wir arbeiten mit Olivenholz, welches von Natur aus besonders hart und widerstandsfähig ist, gleichwohl nach Bearbeitung eine samtweiche Oberfläche aufweist. Die knorrigen Bäume werden insbesondere im Mittelmeerraum angebaut, wo der Olivenbaum über viele Jahrzehnte reichhaltige Erträge liefert. Erst wenn die Bäume wegen ihres Alters kaum noch Früchte tragen, werden sie abgeholzt.", "Das Holz, das wir aus Italien beziehen, wird für viele verschiedene Gegenstände verwendet, so auch für unsere liebevoll gefertigten variablen Untersetzer, die sich für Töpfe und Terrinen unterschiedlicher Größe eignen - das schlichte Design in Verbindung mit edlem Olivenholz wertet jeden schön gedeckten Tisch auf."]} 
                measure={"ca. 21cm"}
                price={"14.99 €"} 
                flipCard={flipCard}
                setSlideImages={setSlideImages}
                productURL={"https://www.etsy.com/de-en/listing/1437291153/unique-olive-wood-card-holder-photo?variation0=4142356317"}
                show={visibleAreas['cardHolder']} />

                <Product 
                language={language}
                idNumber={4} 
                title={"Hochwertige Olivenholz Untersetzer (4er Set)"} 
                imageSrc={["https://www.threeleaves.org/images/Hochwertige%20Olivenholz%20Untersetzer%20%284er%20Set%29/box%20steht.jpg", "https://www.threeleaves.org/images/Hochwertige%20Olivenholz%20Untersetzer%20%284er%20Set%29/halb%20offen%20liegend.jpg", "https://www.threeleaves.org/images/Hochwertige%20Olivenholz%20Untersetzer%20%284er%20Set%29/halb%20offen%20mit%20karte.jpg", "https://www.threeleaves.org/images/Hochwertige%20Olivenholz%20Untersetzer%20%284er%20Set%29/h%C3%B6lzer%20liegen.jpg", "https://www.threeleaves.org/images/Hochwertige%20Olivenholz%20Untersetzer%20%284er%20Set%29/Ma%C3%9Fe%20untersetzer%20stab.jpg", "https://www.threeleaves.org/images/Hochwertige%20Olivenholz%20Untersetzer%20%284er%20Set%29/offen%20mit%20box%20stehen.jpg", "https://www.threeleaves.org/images/Hochwertige%20Olivenholz%20Untersetzer%20%284er%20Set%29/st%C3%A4be%20in%20reihe.jpg" ]}
                contentText={["Unser Foto- oder Kartenhalter aus Olivenholz sind ein wunderschönes und praktisches Accessoire für Zuhause oder das Büro. Das natürliche Olivenholz gibt jedem Halter ein einzigartiges und rustikales Aussehen. Der Halter besteht aus einem Olivenholzstück, in dem Schlitze eingearbeitet sind, um Fotos oder Karten aufzustellen.", "Die Schlitze sind breit genug, um Fotos in verschiedenen Größen zu halten und bieten dabei eine stabile Unterstützung. Der Fotohalter ist handgefertigt und wurde sorgfältig poliert, um eine glatte und glänzende Oberfläche zu erhalten. Es ist eine großartige Möglichkeit, Erinnerungen und Fotos zu präsentieren und gleichzeitig ein Stück natürlicher Schönheit in Ihr Zuhause oder Büro zu bringen.", "Dies kann auch für Hochzeiten als Namenschildhalter verwendet werden und ist ein wunderbares Andenken!"]} 
                measure={"27cm"}
                price={"24.99 €"} 
                flipCard={flipCard}
                setSlideImages={setSlideImages}
                productURL={"https://www.etsy.com/de-en/listing/1340459483/olive-wood-coasters-in-an-exclusive-gift?pro=1"}
                show={visibleAreas['trivet']} />

                <Product 
                language={language}
                idNumber={5} 
                title={"Olivenholz Kanteln | Rohlinge"} 
                imageSrc={["https://www.threeleaves.org/images/Olivenholz%20Kanteln%20%3B%20Rohlinge%20%202x2x30cm/20231110_180051149_iOS.jpg", "https://www.threeleaves.org/images/Olivenholz%20Kanteln%20%3B%20Rohlinge%20%202x2x30cm/20231110_180443167_iOS.jpg", "https://www.threeleaves.org/images/Olivenholz%20Kanteln%20%3B%20Rohlinge%20%202x2x30cm/block%203x3.jpg", "https://www.threeleaves.org/images/Olivenholz%20Kanteln%20%3B%20Rohlinge%20%202x2x30cm/Ma%C3%9Fe%20extra%20space.jpg", "https://www.threeleaves.org/images/Olivenholz%20Kanteln%20%3B%20Rohlinge%20%202x2x30cm/Mikado.jpg" ]}
                contentText={["Wir arbeiten mit Olivenholz, welches von Natur aus besonders hart und widerstandsfähig ist, gleichwohl nach Bearbeitung eine samtweiche Oberfläche aufweist. Die knorrigen Bäume werden insbesondere im Mittelmeerraum angebaut, wo der Olivenbaum über viele Jahrzehnte reichhaltige Erträge liefert. Erst wenn die Bäume wegen ihres Alters kaum noch Früchte tragen, werden sie abgeholzt.", "Das Holz, das wir aus Italien beziehen, wird für viele verschiedene Gegenstände verwendet, so auch für unsere liebevoll gefertigten variablen Untersetzer, die sich für Töpfe und Terrinen unterschiedlicher Größe eignen - das schlichte Design in Verbindung mit edlem Olivenholz wertet jeden schön gedeckten Tisch auf."]} 
                measure={"ca. 21cm"}
                price={"ab 0.99 €"} 
                flipCard={flipCard}
                setSlideImages={setSlideImages}
                productURL={"https://www.etsy.com/de-en/listing/1462876632/olive-wood-scantling-blank-diy-wood?pro=1"}
                show={visibleAreas['wood']} />

                <Product 
                language={language}
                idNumber={6} 
                title={"Brettchen (ca. 30cm)"} 
                imageSrc={["https://www.threeleaves.org/images/Produktfotos/4_1.jpg", "https://www.threeleaves.org/images/Produktfotos/4_2.jpg", "https://www.threeleaves.org/images/Produktfotos/4_3.jpg" ]} 
                contentText={["Verleihen Sie Ihrer Küche einen Hauch von Eleganz mit unserem handgefertigten Olivenholzbrettchen. Jedes Brettchen ist ein Unikat, gefertigt von erfahrenen Handwerkern aus hochwertigem Olivenholz.", "Das natürliche Muster des Holzes macht jedes Brettchen zu einem Kunstwerk. Verwenden Sie es zum Schneiden von Obst, Gemüse oder Käse oder als stilvolle Präsentationsfläche für Häppchen und Snacks.", "Pflegeleicht und langlebig, kann das Olivenholzbrettchen leicht mit einem feuchten Tuch gereinigt werden und behält auch nach vielen Jahren seinen natürlichen Glanz und seine Schönheit.", "Mit unserem Olivenholzbrettchen wird jede Mahlzeit zu einem besonderen Erlebnis."]} 
                price={""} 
                flipCard={flipCard}
                setSlideImages={setSlideImages}
                productURL={"https://www.etsy.com/de-en/listing/1340459483/high-quality-and-handmade-olive-wood?crt=1"}
                show={visibleAreas['boards']} />


                <Product 
                language={language}
                idNumber={7} 
                title={"Anti Stress Kugeln aus Olivenholz "} 
                imageSrc={["https://www.threeleaves.org/images/Produktfotos/Anti-Stress-Kugeln to go aus Olivenholz/1651405727aa.jpg", "https://www.threeleaves.org/images/Produktfotos/Anti-Stress-Kugeln to go aus Olivenholz/1651405727.jpg", "https://www.threeleaves.org/images/Produktfotos/Anti-Stress-Kugeln to go aus Olivenholz/1651405727a.jpg" ]}
                contentText={["Stress adé! Diese Kugeln, aus wunderschön anzusehendem Olivenholz, sind stilvoll in einer Trage-Schachtel verpackt und eignen sich, durch ihre kleine Größe, hervorragend für Anfänger. In der traditionellen chinesischen Medizin werden die Kugeln zur Aktivierung der Reflexzonen an den Handinnenflächen eingesetzt. Durch das Drehen werden diese massiert und der Kreislauf, Stoffwechsel und die Organe aktiviert. Die gleichförmige Bewegung steigert die Harmonisierung des Gehirns und erhöht die Konzentrationsfähigkeit. Zudem wirkt sie ausgleichend auf hohen Blutdruck, beruhigt und hilft beim Stressabbau. Darüber hinaus erhöht die Nutzung der Kugeln die Beweglichkeit, Geschicklichkeit und Feinmotorik der Finger. Die Arm-, Schulter- und Handmuskulatur wird gestärkt und entspannt. Konstantes Trainieren hilft, Symptome der Arthrose, Polyarthritis oder Rheuma zu lindern. Auch sind sie eine Wohltat bei starker Beanspruchung der Finger, wie z.B. längeres Schreiben an der Tastatur. "]} 
                measure={"Größe/Maße 2 Kugeln: je ca. 3,5 cm Durchmesser Trage-Schachtel: ca. 9 x 7 x 3,5 cm"}
                price={""} 
                flipCard={flipCard}
                setSlideImages={setSlideImages}
                productURL={"https://www.etsy.com/de-en/listing/1340459483/high-quality-and-handmade-olive-wood?crt=1"}
                show={visibleAreas['other']} />

                <Product 
                language={language}
                idNumber={8} 
                title={"Moderner Eierbecher"} 
                imageSrc={["https://www.threeleaves.org/images/Produktfotos/Eierbecher%20-%20Trou%C3%A9/1651730957.jpg", "https://www.threeleaves.org/images/Produktfotos/Eierbecher%20-%20Trou%C3%A9/1651730837.jpg", "https://www.threeleaves.org/images/Produktfotos/Eierbecher%20-%20Trou%C3%A9/1651730837a.jpg", "https://www.threeleaves.org/images/Produktfotos/Eierbecher%20-%20Trou%C3%A9/1651730979.jpg", "https://www.threeleaves.org/images/Produktfotos/Eierbecher%20-%20Trou%C3%A9/1651730747.jpg" ]}
                contentText={[" Reduzierte Form – unerwartetes Design Schlicht, schön und praktisch – so präsentiert sich der Eierbecher Troué in unserem Sortiment. Auf dem Frühstückstisch präsentiert er sich als Eierbecher und am Abend verwandelt er sich, bei Bedarf, in einen Serviettenring."]} 
                measure={"Maße: ca. 5,5 x 5,5 cm "}
                price={""} 
                flipCard={flipCard}
                setSlideImages={setSlideImages}
                productURL={"https://www.etsy.com/de-en/listing/1340459483/high-quality-and-handmade-olive-wood?crt=1"}
                show={visibleAreas['other']} />

                <Product 
                language={language}
                idNumber={9} 
                title={"Frühstücksbrettchen 22x14cm"} 
                imageSrc={["https://www.threeleaves.org/images/Produktfotos/Fr%C3%BChst%C3%BCcksbrett%20%2822x14cm%29%20runde%20Ecken/1613129260.jpg", "https://www.threeleaves.org/images/Produktfotos/Fr%C3%BChst%C3%BCcksbrett%20%2822x14cm%29%20runde%20Ecken/1613129260a.jpg", "https://www.threeleaves.org/images/Produktfotos/Fr%C3%BChst%C3%BCcksbrett%20%2822x14cm%29%20runde%20Ecken/1613120941.jpg", "https://www.threeleaves.org/images/Produktfotos/Fr%C3%BChst%C3%BCcksbrett%20%2822x14cm%29%20runde%20Ecken/1613129281.jpg" ]}
                contentText={['Dieses eher dünn geschnittene Frühstücksbrettchen - mit abgerundeten Ecken - eignet sich hervorragend für einen guten Start in den Tag. Du wünscht eine Gravur? Dann wähle eine der angebotenen Schriftarten aus (keine Sonderzeichen oder ähnliches) und teile uns die gewünschte Schriftart und den zu gravierenden Text im Feld "Anmerkung zur Bestellung" im Warenkorb mit. Wird uns keine Schriftart genannt, verwenden wir immer Berlin Sans FB. Die Position der Gravur für dieses Produkt ist immer rechts unten. Es kann aufgrund starker Maserung leichte Positionierungsabweichungen der Gravur geben. ']} 
                measure={"Maße 22 x 14 cm, Stärke 1 cm"}
                price={""} 
                flipCard={flipCard}
                setSlideImages={setSlideImages}
                productURL={"https://www.etsy.com/de-en/listing/1340459483/high-quality-and-handmade-olive-wood?crt=1"}
                show={visibleAreas['other']} />

                <Product 
                language={language}
                idNumber={10} 
                title={"Futternapf (0,2l Metallschale)"} 
                imageSrc={["https://www.threeleaves.org/images/Produktfotos/Futternapf%20LUCKY%20%280%2C2%20l-Metallschale%29%20f%C3%BCr%20Hunde%20%26%20Katzen/image.jpg", "https://www.threeleaves.org/images/Produktfotos/Futternapf%20LUCKY%20%280%2C2%20l-Metallschale%29%20f%C3%BCr%20Hunde%20%26%20Katzen/aa.jpg", "https://www.threeleaves.org/images/Produktfotos/Futternapf%20LUCKY%20%280%2C2%20l-Metallschale%29%20f%C3%BCr%20Hunde%20%26%20Katzen/1654603293.jpg" ]} 
                contentText={['Schöner Futternapf mit Metallschale (0,2 Liter). Das Olivenholz ist geölt.  100% Natur! Wir haben auf den Einsatz von künstlichem Holzschutz komplett verzichtet. Durch mehrfache Behandlung mit Olivenöl ist das Holz gegen Schmutz und Feuchtigkeit nachhaltig geschützt. Auf der Unterseite befinden sich Antirutsch-Pads für einen sicheren Stand, so dass die gesamte Futterstation beim Fressen nicht "wandern" kann. Die beiden Metallschalen aus Edelstahl sind rostfrei und spülmaschinenfest.']} 
                price={""} 
                flipCard={flipCard}
                setSlideImages={setSlideImages}
                productURL={"https://www.etsy.com/de-en/listing/1340459483/high-quality-and-handmade-olive-wood?crt=1"}
                show={visibleAreas['other']} />

                <Product 
                language={language}
                idNumber={11} 
                title={"Futterstation (2x0,2l Metallschale)"} 
                imageSrc={["https://www.threeleaves.org/images/Produktfotos/Futterstation%20LUCKY%20%282%20x%200%2C2%20l%20Metallschale%29%20f%C3%BCr%20kleine%20Hunde%20%26%20Katzen/1654603305.jpg", "https://www.threeleaves.org/images/Produktfotos/Futterstation%20LUCKY%20%282%20x%200%2C2%20l%20Metallschale%29%20f%C3%BCr%20kleine%20Hunde%20%26%20Katzen/image.jpg", "https://www.threeleaves.org/images/Produktfotos/Futterstation%20LUCKY%20%282%20x%200%2C2%20l%20Metallschale%29%20f%C3%BCr%20kleine%20Hunde%20%26%20Katzen/a.jpg" ]} 
                contentText={["Schöne Futterstation mit 2 Metallschalen (Futter und Wasser zu je 0,2 Liter).  100% Natur! Wir haben auf den Einsatz von künstlichem Holzschutz komplett verzichtet. Durch mehrfache Behandlung mit Olivenöl ist das Holz gegen Schmutz und Feuchtigkeit nachhaltig geschützt. Auf der Unterseite befinden sich Antirutsch-Pads für einen sicheren Stand, so dass die gesamte Futterstation beim Fressen nicht wandern kann. Die beiden Metallschalen aus Edelstahl sind rostfrei und spülmaschinenfest. "]} 
                price={""} 
                flipCard={flipCard}
                setSlideImages={setSlideImages}
                productURL={"https://www.etsy.com/de-en/listing/1340459483/high-quality-and-handmade-olive-wood?crt=1"}
                show={visibleAreas['other']} />

                <Product 
                language={language}
                idNumber={12} 
                title={"Tomaten-, Steakmesser mit Olivenholzgriff"} 
                imageSrc={["https://www.threeleaves.org/images/Produktfotos/Gem%C3%BCse-%20Tomatenmesser%2C%20Steakmesser%20mit%20Olivenholzgriff/1650371417.jpg", "https://www.threeleaves.org/images/Produktfotos/Gem%C3%BCse-%20Tomatenmesser%2C%20Steakmesser%20mit%20Olivenholzgriff/1650371332.jpg", "https://www.threeleaves.org/images/Produktfotos/Gem%C3%BCse-%20Tomatenmesser%2C%20Steakmesser%20mit%20Olivenholzgriff/1650371332a.jpg", "https://www.threeleaves.org/images/Produktfotos/Gem%C3%BCse-%20Tomatenmesser%2C%20Steakmesser%20mit%20Olivenholzgriff/image.jpg" ]} 
                contentText={[" In hochwertiger Qualität! Eine tolle Kombination – aus hochwertiger Edelstahlklinge und wunderschön gemasertem Olivenholzgriff – so schneidet das Gemüsemesser einwandfrei. Die rostfreie Edelstahlklinge mit geriffelter Klinge der Firma Schwertkrone (Solinger Markenware) ist superscharf. "]} 
                measure={"Länge ca. 23,5 cm"}
                price={""} 
                flipCard={flipCard}
                setSlideImages={setSlideImages}
                productURL={"https://www.etsy.com/de-en/listing/1340459483/high-quality-and-handmade-olive-wood?crt=1"}
                show={visibleAreas['other']} />

                <Product 
                language={language}
                idNumber={13} 
                title={"Pfannenwender"} 
                imageSrc={["https://www.threeleaves.org/images/Produktfotos/Pfannenwender%20%28ohne%20L%C3%B6cher%29%20aus%20Olivenholz/image.jpg", "https://www.threeleaves.org/images/Produktfotos/Pfannenwender%20%28ohne%20L%C3%B6cher%29%20aus%20Olivenholz/1648463670.jpg" ]} 
                contentText={["Zur täglichen Verwendung sehr gut geeignet. Kein Problem in kochendem Wasser oder heißem Fett. Den Pfannenwender gibt es auch mit 6 Löchern."]} 
                measure={"Länge ca. 30 cm"}
                price={""} 
                flipCard={flipCard}
                setSlideImages={setSlideImages}
                productURL={"https://www.etsy.com/de-en/listing/1340459483/high-quality-and-handmade-olive-wood?crt=1"}
                show={visibleAreas['other']} />

            </div>
          </div>
        </div>
  )
}

export default Work
