import { useState } from "react";

const Team = ({language}) => {

    const titleEN = "THE FOUNDERS";
    const titleDE = "DIE GRÜNDER";

    const subtitleEN = "The team that runs this company";
    const subtitleDE = "Das Team, das dieses Unternehmen führt";

    const box1titleEN = "Family run";
    const box1subtitleEN = "A small, honest family run business with the heart at the right place. And some more text here to make it fit.";
    const box1titleDE = "Familienbetrieb";
    const box1subtitleDE = "Ein Stück Natur in unsere Produkte einfließen zu lassen, macht unsere Produkte so besonders und gibt ihnen ihre Seele.";
    
    const box2titleEN = "Passion";
    const box2subtitleEN = "We are super excited to create  and optimise products that leads to the perfect customer experience.";
    const box2titleDE = "Hingabe";
    const box2subtitleDE = "Wir freuen uns sehr, Produkte zu entwickeln und zu optimieren, die zu einem perfekten Kundenerlebnis führen.";
    
    const box3titleEN = "Natural resources";
    const box3subtitleEN = "To include a piece of nature into our products is what makes our products so special and give them their soul.";
    const box3titleDE = "Natürliche Ressourcen";
    const box3subtitleDE = "Ein Stück Natur in unsere Produkte einfließen zu lassen, macht unsere Produkte so besonders und gibt ihnen ihre Seele.";

    const contactEN = " Click here for ";
    const contactDE = " Klick hier um mehr zu erfahren";

    const [animating, setAnimating] = useState([false, false, false]);
    const toggleAnimating = (index) => {
      setAnimating(prevState => prevState.map((item, idx) => idx === index ? !item : item))
    };
  

    const flipCard = (e, cardNumber, flipIn) => {

        if (e.target instanceof HTMLButtonElement || e.target instanceof HTMLAnchorElement || e.target instanceof HTMLSpanElement)
        {
            return;
        } 

        const card = document.getElementById("team-card" + cardNumber + "-1");
        const card2 = document.getElementById("team-card" + cardNumber + "-2");
        const flipSpeedMS = 400;
    
        if (animating[cardNumber - 1]) {
            return;
        }
        
        toggleAnimating(cardNumber - 1);
        window.setTimeout ( function() {  
          toggleAnimating(cardNumber - 1);
          }, flipSpeedMS);
    
        if(flipIn) {
            card.classList = "flip-style w3-card top-card"
            card2.classList = "flip-style2 w3-card bottom-card"
            window.setTimeout ( function() {  
                card.classList = "flip-style w3-card bottom-card"
                card.style.opacity = "0%";
                card2.classList = "flip-style2 w3-card top-card"   
                card2.style.opacity = "99%";  
              }, 200);
            window.setTimeout ( function() {   
                card.classList = "w3-card bottom-card"
                card2.classList = "w3-card top-card" 
              }, 410);
        } else {
            card.classList = "flip-style2 w3-card top-card"
            card2.classList = "flip-style w3-card bottom-card"
            window.setTimeout ( function() {  
                card.classList = "flip-style2 w3-card bottom-card"
                card.style.opacity = "99%";
                card2.classList = "flip-style w3-card top-card"   
                card2.style.opacity = "0%"; 
              }, 200);
            window.setTimeout ( function() {   
                card.classList = "w3-card top-card"
                card2.classList = "w3-card bottom-card" 
              }, 410);
        }
    }

  return (
    <div className="w3-container2" style={{paddingBottom: "128px"}}>
              <img className="left-flowers" src="https://www.threeleaves.org/images/olive_tree_branch.png" alt="Jane"></img>
        <h1 className="w3-center" style={{fontSize: "30px"}}>{language === "en" ? titleEN : titleDE}</h1>
        <p className="w3-center" style={{fontSize: "16px", fontWeight: "lighter"}}>{language === "en" ? subtitleEN : subtitleDE}</p>
        <div id="product-wrapper">
        <div className="product-container">
            <div className="card-container founder-image-hover">
                <div onClick={(e) => flipCard(e, 0, true)} id={"team-card0" + "-1"} className="w3-card top-card">
                    <div className="w3-container">
                        <div className="product-image">
                            <img src="https://www.threeleaves.org/images/sas.jpg" alt="Not found" style={{width: "100%", maxHeight: "360px"}}></img>
                        </div>
                        <h3 className="product-title" style={{transform: "translateY(55px)"}}>Sascha Denkinger</h3>
                        <h4 className="product-title2" style={{textAlign: "right"}}>Stakeholder</h4>
                    </div>
                </div>
                <div onClick={(e) => flipCard(e, 0, false)} id={"team-card0" + "-2"} className="w3-card bottom-card" style={{opacity: "0%"}}>
                    <div style={{backgroundColor: "white"}}>
                        <div className="w3-container" style={{position: "relative"}}>
                            <h3 style={{fontSize: "20px", marginTop: "25px"}}>Sascha Denkinger</h3>
                            <div style={{paddingTop: "50px"}}>
                                <p className="cardback-content"><b>E-mail: </b>  s.denkinger@threeleaves.org</p><br></br>
                                <p className="cardback-content"><b>Telephone: </b>  +49 15738329231</p><br></br>
                                <p className="cardback-content"><i>"In the information age, you don't teach philosophy as they did after feudalism. You perform it. If Aristotle were alive today he'd have a talk show."</i></p>
                            </div>
                        </div>
                    </div>
                    <div style={{textAlign: "center", bottom: 20, position: "absolute", left: 50, right: 50}}>
                    </div>
                </div>
            </div>
            <div className="card-container founder-image-hover">
                <div onClick={(e) => flipCard(e, 1, true)} id={"team-card1" + "-1"} className="w3-card top-card">
                    <div className="w3-container">
                        <div className="product-image">
                            <img src="https://www.threeleaves.org/images/jox.jpg" alt="Not found" style={{width: "100%", maxHeight: "360px"}}></img>
                        </div>
                        <h3 className="product-title" style={{transform: "translateY(55px)"}}>Joachim Denkinger</h3>
                        <h4 className="product-title2" style={{textAlign: "right"}}>CFO</h4>
                    </div>
                </div>
                <div onClick={(e) => flipCard(e, 1, false)} id={"team-card1" + "-2"} className="w3-card bottom-card" style={{opacity: "0%"}}>
                    <div style={{backgroundColor: "white"}}>
                        <div className="w3-container" style={{position: "relative"}}>
                            <h3 style={{fontSize: "20px", marginTop: "25px"}}>Joachim Denkinger</h3>
                            <div style={{paddingTop: "50px"}}>
                                <p className="cardback-content"><b>E-mail: </b>  j.denkinger@threeleaves.org</p><br></br>
                                <p className="cardback-content"><b>Telephone: </b>  +49 15738329231</p><br></br>
                                <p className="cardback-content"><i>"In the information age, you don't teach philosophy as they did after feudalism. You perform it. If Aristotle were alive today he'd have a talk show."</i></p>
                            </div>                        </div>
                    </div>
                    <div style={{textAlign: "center", bottom: 20, position: "absolute", left: 50, right: 50}}>
                    </div>
                </div>
            </div>
            <div className="card-container founder-image-hover">
                <div onClick={(e) => flipCard(e, 2, true)} id={"team-card2" + "-1"} className="w3-card top-card">
                    <div className="w3-container">
                        <div className="product-image">
                            <img src="https://www.threeleaves.org/images/luk.jpg" alt="Not found" style={{width: "100%", maxHeight: "360px"}}></img>
                        </div>
                        <h3 className="product-title" style={{transform: "translateY(55px)"}}>Lukas Denkinger</h3>
                        <h4 className="product-title2" style={{textAlign: "right"}}>CEO</h4>
                    </div>
                </div>
                <div onClick={(e) => flipCard(e, 2, false)} id={"team-card2" + "-2"} className="w3-card bottom-card" style={{opacity: "0%"}}>
                    <div style={{backgroundColor: "white"}}>
                        <div className="w3-container" style={{position: "relative"}}>
                            <h3 style={{fontSize: "20px", marginTop: "25px"}}>Lukas Denkinger</h3>
                            <div style={{paddingTop: "50px"}}>
                                <p className="cardback-content"><b>E-mail: </b>  l.denkinger@threeleaves.org</p><br></br>
                                <p className="cardback-content"><b>Telephone: </b>  +49 15738329231</p><br></br>
                                <p className="cardback-content"><i>"In the information age, you don't teach philosophy as they did after feudalism. You perform it. If Aristotle were alive today he'd have a talk show."</i></p>
                            </div>                        </div>
                    </div>
                    <div style={{textAlign: "center", bottom: 20, position: "absolute", left: 50, right: 50}}>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Team
