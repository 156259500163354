import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCube, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
const SlideCube = ({images}) => {

  const swiperRef = useRef();

  const makeSlides = () => {
      const row = [];
      for (var i = 0; i < images.length; i++) {
        row.push(<SwiperSlide><img src={images[i]} className="no-highlight" alt="Not found"></img></SwiperSlide>);
      }
      return row;
    };

  return (
    <div>
      <Swiper
        effect={'cube'}
        grabCursor={true}
        cubeEffect={{
          shadow: false,
          slideShadows: false,
          shadowOffset: 100,
          shadowScale: 0.94,
        }}
        pagination={true}
        loop={true}
        modules={[EffectCube, Pagination, Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        className="mySwiper"
      >
        {makeSlides()}
      </Swiper>
      <div>
        <div>
          <a style={{transform: "translateY(70px)"}} className="prev showOnHover" onClick={() => swiperRef.current?.slidePrev()}>❮</a>
          <a style={{transform: "translateY(70px)"}} className="next showOnHover" onClick={() => swiperRef.current?.slideNext()}>❯</a>
        </div>
      </div>
    </div>
  )
}

export default SlideCube
