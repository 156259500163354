import { useState } from "react";

const Impressum = ({language}) => {

  const titleEN = "IMPRESSUM";
  const titleDE = "IMPRESSUM";

  const subtitle1 = "Three Leaves GmbH";
  const subtitle2 = "Lukas Denkinger";

  const locationEN = "Sebastianstraße 19, 100179 Berlin";
  const locationDE = "Sebastianstraße 19, 100179 Berlin";

  const [isActive, setIsActive] = useState(false);

  const scaleUp = () => {
     setIsActive(true);
  }
  
  const scaleDown = () => {
    setIsActive(false);
  }

  const closeImpressum = () => {

    if (isActive)
    return;

    window.setTimeout ( function() {  
      document.getElementById("impressum").style.display = "none";
      }, 50);

  }


  return (
    <div id="impressum" onClick={() => closeImpressum()} >
      <div>
        <div className="w3-container contact-padding" >
        <div className="w3-card" style={{marginTop: "0px", padding: "2% 5% 2% 2%", transform: isActive ? "scale(1.01, 1.01)" : "scale(1.0, 1.0)", backgroundColor: "white"}} onMouseEnter={scaleUp} onMouseLeave={scaleDown}>
          <h1 style={{marginTop: "0px", padding: "0% 0% 0%"}} className="w3-center">{language === "en" ? titleEN : titleDE}</h1>
          <p className="w3-center w3-large">{subtitle1}</p>
          <p className="w3-center w3-large">{subtitle2}</p>
          <div style={{marginTop: "30px", padding: "0% 0% 0%"}}>
              <div style={{display: "block", justifyContent: "left"}}>
                <div style={{paddingLeft: "50%", transform: "translateX(-120px)"}}>
                  <p style={{textAlign: "left"}}><i className="fa fa-map-marker fa-fw w3-xxlarge w3-margin-right theme-color"></i>{language === "en" ? locationEN : locationDE}</p>
                  <p style={{textAlign: "left"}}><i className="fa fa-book fa-fw w3-xxlarge w3-margin-right theme-color"></i>Umsatzsteuer-Identifikationsnummer: DE354292330</p>
                  <p style={{textAlign: "left"}}><i className="fa fa-phone fa-fw w3-xxlarge w3-margin-right theme-color"></i> Phone: +49 157 3832 9231</p>
                  <p style={{textAlign: "left"}}><i className="fa fa-envelope fa-fw w3-xxlarge w3-margin-right theme-color"> </i> Email: info@threeleaves.org</p>
                </div>
                <div>
                  <br></br>
                  <div style={{marginTop: "0px", padding: "0% 5% 0%"}}>

                  </div>
              </div>
              </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Impressum
