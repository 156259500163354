const About = ({language}) => {

  const titleEN = "THE THREE LEAVES";
  const titleDE = "THE THREE LEAVES";

  const subtitleEN = "Key features of our company";
  const subtitleDE = "Hauptmerkmale unseres Unternehmens";
  
  const box1titleEN = "Family run";
  const box1subtitleEN = "A small, honest family run business with the heart at the right place.";
  const box1titleDE = "Familienbetrieb";
  const box1subtitleDE = "Ein kleiner ehrlicher Familienbetrieb mit dem Herz am rechten Fleck.";
  
  const box2titleEN = "Passion";
  const box2subtitleEN = "We are super excited to create  and optimise products that leads to the perfect customer experience.";
  const box2titleDE = "Hingabe";
  const box2subtitleDE = "Wir freuen uns sehr, Produkte zu entwickeln und zu optimieren, die zu einem perfekten Kundenerlebnis führen.";
  
  const box3titleEN = "Natural resources";
  const box3subtitleEN = "To include a piece of nature into our products is what makes our products so special and give them their soul.";
  const box3titleDE = "Natürliche Ressourcen";
  const box3subtitleDE = "Ein Stück Natur in unsere Produkte einfließen zu lassen, macht unsere Produkte so besonders und gibt ihnen ihre Seele.";
  
  const box4titleEN = "Design";
  const box4subtitleEN = "Simple. Functional. Durable.";
  const box4titleDE = "Entwerfen";
  const box4subtitleDE = "Einfach. Funktionell. Dauerhaft.";

  return (
    <div className="w3-container" style={{padding: "40px 1px"}} id="about">
      <img className="right-flowers" src="https://www.threeleaves.org/images/olive_tree_branch.png" alt="Jane"></img>
      <h1 className="w3-center" style={{fontSize: "65px", fontWeight: "bolder", fontFamily: "Cormorant"}}>{language === "en" ? titleEN : titleDE}</h1>
      <p className="w3-center" style={{transform: "translateY(-20px)", fontFamily: "Cormorant", fontSize: "28px"}}>{language === "en" ? subtitleEN : subtitleDE}</p>
      <div className="w3-center">
            <img src="https://www.threeleaves.org/images/founders.jpg" className="founder-image w3-card" alt="Not found"></img>
         </div>
      <div className="w3-row-padding w3-center " style={{marginTop: "74px"}}>
      <section className="wow fadeIn">
        <div className="w3-quarter">
          <i className="fa fa-duotone fa-users w3-margin-bottom w3-jumbo w3-center wow fadeInUp" style={{color: "darkslateblue"}}></i>
          <p className="w3-large">{language === "en" ? box1titleEN : box1titleDE}</p>
          <p>{language === "en" ? box1subtitleEN : box1subtitleDE}</p>
        </div>
        <div className="w3-quarter">
          <i className="fa fa-duotone fa-fire w3-margin-bottom w3-jumbo wow fadeInUp" style={{color: "darkred"}}></i>
          <p className="w3-large">{language === "en" ? box2titleEN : box2titleDE}</p>
          <p>{language === "en" ? box2subtitleEN : box2subtitleDE}</p>
        </div>
        </section>

        <section className="wow fadeIn">
        <div className="w3-quarter">
          <i className="fa fa-duotone fa-tree w3-margin-bottom w3-jumbo wow fadeInUp" style={{color: "green"}}></i>
          <p className="w3-large">{language === "en" ? box3titleEN : box3titleDE}</p>
          <p>{language === "en" ? box3subtitleEN : box3subtitleDE}</p>
        </div>
        <div className="w3-quarter">
          <i className="fa fa-duotone fa-pencil w3-margin-bottom w3-jumbo wow fadeInUp" style={{color: "goldenrod"}}></i>
          <p className="w3-large">{language === "en" ? box4titleEN : box4titleDE}</p>
          <p>{language === "en" ? box4subtitleEN : box4subtitleDE}</p>
        </div>
        </section>
      </div>
    </div>
  )
}

export default About
