import { Link } from "react-router-dom"

const Footer = ({language}) => {

  const toTopBtnEN = " To the top";
  const toTopBtnDE = " Zum Seitenanfang";

  const contactEN = " CONTACT";
  const contactDE = " KONTAKT";

  const openContact = () => {

    window.setTimeout ( function() {  
      document.getElementById("contact").style.display = "block";
      }, 50);

  }

  const openImpressum = () => {

    window.setTimeout ( function() {  
      document.getElementById("impressum").style.display = "block";
      }, 50);

  }

  return (
  <footer className="w3-center w3-padding-64 wow fadeIn w3-card" style={{backgroundColor: "white"}}>
    <p style={{paddingTop: "30px"}}><a href="#home" className="w3-button wow fadeIn theme-color-bg"><i className="fa fa-arrow-up"></i>  {language === "en" ? toTopBtnEN : toTopBtnDE}</a></p>
    <div style={{position: "relative", width: "100%"}}>
      <img className="no-highlight" id="main-logo2" src="https://www.threeleaves.org/images/threeleaves_logo.png" alt="John" draggable="false"></img>
    </div>
    <div className="icon-outer-container2">
      <div className="icon-inner-container w3-xxlarge">
        <i className="fa fa-facebook-official w3-hover-opacity"></i>
        <i className="fa fa-instagram w3-hover-opacity"></i>
        <i className="fa fa-snapchat w3-hover-opacity"></i>
        <i className="fa fa-pinterest-p w3-hover-opacity"></i>
        <i className="fa fa-twitter w3-hover-opacity"></i>
        <i className="fa fa-linkedin w3-hover-opacity"></i>
      </div>
    </div>
    <div style={{textAlign: "center", transform: "translateY(-23px)", margin: 0}}>
      <h3 style={{transform: "translateY(10px)"}}>Three Leaves GmbH</h3>
      <span>2022 All Rights Reserved ©</span>
    </div>
    <div style={{display: "flex", flexDirection: "row", justifyContent: "center", transform: "translateY(10px)"}}>
      <div>
        <p onClick={() => openContact()} className="w3-bar-item w3-button"><i className="fa fa-envelope"></i>{language === "en" ? contactEN : contactDE}</p>
      </div>
      <div>
        <p onClick={() => openImpressum()} className="w3-bar-item w3-button"><i className="fa fa-envelope"></i> IMPRESSUM </p>
      </div>
    </div>
  </footer>
  )
}

export default Footer
