const NavBar = ({setLanguage, language, openSideBar}) => {

  const companyEN = " ABOUT";
  const productsEN = " OUR PRODUCTS";
  const contactEN = " CONTACT";

  const companyDE = " ÜBER UNS";
  const productsDE = " PRODUKTE";
  const contactDE = " KONTAKT";
  
  const etsyEN = " Visit us on";
  const etsyDE = " Besuchen Sie uns auf";

  const openContact = () => {

    window.setTimeout ( function() {  
      document.getElementById("contact").style.display = "block";
      }, 50);

  }

  return (
    <div className="navbar">
      <div className="w3-bar w3-white w3-card">

        <a href="#home"  className="w3-bar-item w3-button w3-wide">
          <img src="https://www.threeleaves.org/images/threeleaves_logo.png" id="navbar-logo" alt=""></img>
          <span className="title-font-navbar" style={{paddingLeft: "40px"}}>Three Leaves</span>
        </a>

        <div className="etsy-button-parent">
          <div className="etsy-button" style={language === "en" ? enStyle : deStyle}>
            <a href="https://www.etsy.com/de/shop/ThreeLeavesBerlin?ref=shop-header-name&listing_id=1340459483"  className="w3-bar-item w3-button w3-wide">
              <span className="visit-etsy" style={{paddingRight: "4px", textAlign: "right"}}>{language == "en" ? etsyEN : etsyDE}</span>
              <img src="https://www.threeleaves.org/images/etsy_logo.png" alt="" style={{width: "40px"}}></img>
            </a>
          </div>
        </div>

        <div className="w3-right w3-hide-medium w3-hide-small">
          <a href="#about" className="w3-bar-item w3-button">{language === "en" ? companyEN : companyDE}</a>
          <a href="#work" className="w3-bar-item w3-button"><i className="fa fa-th"></i> {language === "en" ? productsEN : productsDE}</a>
          {/*<a href="#pricing" className="w3-bar-item w3-button"><i className="fa fa-usd"></i> {language == "en" ? companyEN : companyDE}</a>*/}
          <a onClick={() => openContact()} className="w3-bar-item w3-button"><i className="fa fa-envelope"></i> {language === "en" ? contactEN : contactDE}</a>
          <div className="w3-dropdown-hover w3-hide-medium">
          <button className="w3-button" title="Language"><i className="fa fa-globe"></i> <i className="fa fa-caret-down"></i></button>     
          <div className="w3-dropdown-content w3-card-4 w3-bar-block lang-btn">
            <a href="#" className="w3-bar-item w3-button lang-options" onClick={() => setLanguage("en")} style={language === "en" ? activeStyle : inactiveStyle}>English</a>
            <a href="#" className="w3-bar-item w3-button lang-options" onClick={() => setLanguage("de")} style={language === "en" ? inactiveStyle : activeStyle}>Deutsch</a>
          </div>
        </div>  
        </div>  
        <a href="#" className="w3-bar-item w3-button w3-right w3-hide-large" onClick={openSideBar}>
          <i className="fa fa-bars"></i>
        </a>
      </div>
    </div>
  )
}

const activeStyle = {
  textDecorationLine: "none",
  textDecorationStyle: "solid",
  textDecorationColor: "grey",
  textDecorationThickness: "1px",
  fontWeight: "bold",
  letterSpacing: "1px",
  padding: "10%",
  textAlign: "center"
}

const inactiveStyle = {
  textDecorationLine: "none",
  textDecorationStyle: "none",
  textDecorationColor: "black",
  textDecorationThickness: "0px",
  textShadow: "none",
  fontWeight: "normal",
  letterSpacing: "0px",
  padding: "10%",
  textAlign: "center"
}

const enStyle = {
  transform: "translateX(30px)"
}

const deStyle = {
  transform: "translateX(0px)"
}

export default NavBar
