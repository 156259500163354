import React, { useState, useEffect } from 'react';
import SlideCube from './SlideCube';

const Product = ({language, idNumber, title, imageSrc, contentText, measure, price, flipCard, setSlideImages, productURL, show}) => {

  const amazonBuyEN = " Order now on";
  const amazonBuyDE = " Bestellen Sie jetzt auf";

  const fullscreenEN = "Fullscreen";
  const fullscreenDE = "Vollbild";

  const [slideIndex, setSlideIndex] = useState(1);

  const plusSlides = () => {
    let number = slideIndex;
    number++;
    setSlideIndex(number)
    window.setTimeout ( function() {   
      showSlides(number);
    }, 50);
  }

  const minusSlides = () => {
    let number = slideIndex;
    number--;
    setSlideIndex(number)
    window.setTimeout ( function() {   
      showSlides(number);
    }, 50);
  }

  const currentSlide = (n) => {
    setSlideIndex(n);
    showSlides(n);
  }

const showSlides = (n) => {
  let i;
  let numOfSlides = imageSrc.length;
  let number = 0 + n;

  const slides = [];
  for (i = 0; i < numOfSlides; i++) {
    slides.push(document.getElementById("slide" + i + "-" + idNumber));
  }

  const dots = [];
  for (i = 0; i < numOfSlides; i++) {
    dots.push(document.getElementById("dot" + i + "-" + idNumber));
  }

  if (n > numOfSlides) {
    setSlideIndex(1);
    number = 1;
  }    
  if (n < 1) {
    setSlideIndex(numOfSlides);
    number = numOfSlides;
  }
  for (i = 0; i < numOfSlides; i++) {
    slides[i].style.display = "none";  
  }
  for (i = 0; i < numOfSlides; i++) {
    dots[i].className = dots[i].className.replace(" active", "");
  }
  number--;
  slides[number].style.display = "block";  
  dots[number].className += " active";
}

  const onAmazonBtnClick = (e) => {
    
    window.setTimeout ( function() {  
      //document.getElementById("order-now").style.display = "block";
      window.location.href = productURL;
      }, 50);

  }

  const openSlideShow = () => {

    setSlideImages(imageSrc);

    window.setTimeout ( function() {  
      document.getElementById("slideshow-box").style.display = "block";
      }, 50);
  
  }
  

  useEffect(() => {
    window.setTimeout ( function() {   
      currentSlide(0); 
      window.setTimeout ( function() {   
        currentSlide(1); 
      }, 100);
    }, 100);

  }, []);

  const makeDots = () => {
    const row = [];
    for (var i = 0; i < imageSrc.length; i++) {
      row.push(<span key={i} id={"dot" + i + "-" + idNumber} className="dot"></span> );
    }
    return row;
  };

  const makeSlides = () => {
    const row = [];
    for (var i = 0; i < imageSrc.length; i++) {
      row.push(<div key={i} id={"slide" + i + "-" + idNumber} className="mySlides fade">
      <img src={imageSrc[i]} className="w3-hover-opacity2" alt="Not found" style={{width: "100%", maxHeight: "320px"}}></img>
    </div> );
    }
    return row;
  };

  const makeContent = () => {
    const row = [];
    for (var i = 0; i < contentText.length; i++) {
      row.push(<p className="cardback-content" style={{fontSize: "11.2px"}}>{contentText[i]}</p> );
    }
    return row;
  };

  return (
    <div style={show ? activeStyle : inactiveStyle}>
        <div className="card-container">
            <div id={"card" + idNumber + "-1"} className="w3-card top-card">
              <div className="hoverShow">
                <div className="slidecube-parent">
                    { <SlideCube images={imageSrc} />}
                </div>
                <div className="slideshow-button">
                  <div className="slideshow-button-container">  
                      <button className="cta" onClick={() => openSlideShow()}>
                        <i className="fa fa-expand"></i>
                        <span className="button-text">{language === "en" ? fullscreenEN : fullscreenDE}</span>
                      </button>
                  </div>
                </div>
              </div>
              <div className="w3-container w3-hover-opacity" style={{transform:"translateY(100px", height: "120px"}} onClick={(e) => flipCard(e, idNumber, true)}>
                <div>
                  <h3 className="product-title" style={{transform: "translateY(-300px)"}}>{title}</h3>
                  <p style={{fontSize: "26px", color: "#4b573f", fontWeight: "normal", fontFamily: "Caveat", textAlign: "right", transform: "translate(0px, 50px)"}}>{price}</p>
                </div>
              </div>
            </div>
            <div onClick={(e) => flipCard(e, idNumber, false)} id={"card" + idNumber + "-2"} className="w3-card bottom-card" style={{opacity: "0%"}}>
                <div style={{backgroundColor: "white"}}>
                    <div className="w3-container">
                        <h3 style={{fontSize: "16px", marginTop: "25px", marginBottom: 0, fontWeight: "bold"}}>{title}</h3>
                        <div className="w3-container" style={{position: "relative", display: "flex", alignItems: "center", minHeight: "330px", paddingTop: 0, marginTop: 0}}>
                          <div style={{textAlign: "left", position: "absolute", verticalAlign: "middle", paddingRight: "5px", transform: "translateY(-20px)"}}>
                            {makeContent()}
                          </div>
                        </div>
                    </div>
                </div>
                    {/*<p style={{position: "absolute", bottom: 0, right: 10}}>Price: {price}€</p>*/}
                <div style={{textAlign: "center", bottom: 20, position: "absolute", left: 5, right: 5}}>
                    <button className="header-button" onClick={(e) => onAmazonBtnClick(e)}><i className="fa fa-shopping-cart fa-2x1" style={{paddingRight: "5px"}}></i>  {language === "en" ? amazonBuyEN : amazonBuyDE} <img src="https://www.threeleaves.org/images/etsy_logo.png" alt="" style={{width: "40px", marginLeft: "-2px"}}></img></button>
                </div>
            </div>
        </div>
    </div>
  )
}

const activeStyle = {
    display: "block"
  }
  
  const inactiveStyle = {
    display: "none"
  }

export default Product
