import { useState } from "react";
import { useForm } from "react-hook-form";
import validator from "validator";
import axios from "axios";

const Contact = ({language}) => {

  const titleEN = "CONTACT";
  const titleDE = "KONTAKT";

  const subtitleEN = "Send us a message:";
  const subtitleDE = "Schick uns eine Nachricht:";

  const locationEN = " Location: Germany";
  const locationDE = " Standort: Deutschland";

  const nameEN = "Name";
  const nameDE = "Name";

  const emailEN = "Your E-Mail";
  const emailDE = "Deine E-Mail";
  
  const subjectEN = "Subject";
  const subjectDE = "Thema";
  
  const textEN = "Enter text";
  const textDE = "Text eingeben";

  const btnEN = " SEND MESSAGE";
  const btnDE = " NACHRICHT SENDEN";

  const { register, handleSubmit } = useForm();
  const onError = (errors, e) => console.log(errors, e);
  const [emailError, setEmailError] = useState('');

  const [name, setName] = useState("")
  const [mail, setMail] = useState("")
  const [subject, setSubject] = useState("")
  const [text, setText] = useState("")


  const onSubmit = async (e) => {
    //e.preventDefault();'Content-type': 'application/x-www-form-urlencoded'

    if (validator.isEmail(mail)) {
      setEmailError('');

      const config = {
        method: 'POST',
        url: './sendMail.php',
        data: { name: name, mail: mail, subject: subject, text: text },
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      };
      axios(config)
        .then(async (response) => {
          console.log('response', response);
          
          window.setTimeout ( function() {  
            document.getElementById("contact").style.display = "none";
            setName("");
            setMail("");
            setSubject("");
            setText("");
            document.getElementById("Form").reset();
            }, 50);
      
          alert("Email sent, awesome!"); 
        })
        .catch((error) => {
          console.log(`error`, error.response); 
          alert("Oops, something went wrong. Try again");
        });
    } else {
      setEmailError('Enter valid Email!');
    }
  }

  const [isActive, setIsActive] = useState(false);

  const scaleUp = () => {
     setIsActive(true);
  }
  
  const scaleDown = () => {
    setIsActive(false);
  }

  const closeContact = () => {

    if (isActive)
    return;

    window.setTimeout ( function() {  
      document.getElementById("contact").style.display = "none";
      }, 50);

  }


  return (
    <div id="contact" onClick={() => closeContact()}>
      <div>
        <div className="w3-container contact-padding" >
        <div className="w3-card" style={{marginTop: "0px", padding: "2% 5% 2% 2%", transform: isActive ? "scale(1.01, 1.01)" : "scale(1.0, 1.0)", backgroundColor: "white"}} onMouseEnter={scaleUp} onMouseLeave={scaleDown}>
          <h1 style={{marginTop: "0px", padding: "0% 0% 0%"}} className="w3-center">{language === "en" ? titleEN : titleDE}</h1>
          <p className="w3-center w3-large">{language === "en" ? subtitleEN : subtitleDE}</p>
          <div style={{marginTop: "30px", padding: "0% 0% 0%"}}>
              <div style={{display: "block", justifyContent: "left"}}>
                <div style={{paddingLeft: "50%", transform: "translateX(-120px)"}}>
                  <p style={{textAlign: "left"}}><i className="fa fa-map-marker fa-fw w3-xxlarge w3-margin-right theme-color"></i>{language === "en" ? locationEN : locationDE}</p>
                  {/*<p><i className="fa fa-phone fa-fw w3-xxlarge w3-margin-right theme-color"></i> Phone: +49 176 7215 1455</p>*/}
                  <p style={{textAlign: "left"}}><i className="fa fa-envelope fa-fw w3-xxlarge w3-margin-right theme-color"> </i> Email: info@threeleaves.org</p>
                </div>
                <div>
                  <br></br>
                  <div style={{marginTop: "0px", padding: "0% 5% 0%"}}>
                    <form id="Form" action="#" name="Form" onSubmit={handleSubmit(onSubmit, onError)}>
                      <p><input className="w3-input w3-border" type="text" placeholder={language === "en" ? nameEN : nameDE} required {...register("name")}  onChange={(e) => setName(e.target.value)}></input></p>
                      <p><input className="w3-input w3-border" type="text" placeholder={language === "en" ? emailEN : emailDE} required {...register("mail")}  onChange={(e) => setMail(e.target.value)}></input></p>
                      <p><input className="w3-input w3-border" type="text" placeholder={language === "en" ? subjectEN : subjectDE} required {...register("subject")}  onChange={(e) => setSubject(e.target.value)}></input></p>
                      <p ><textarea className="w3-input w3-border" rows="5" cols="60" type="text" required {...register("text")} placeholder={language === "en" ? textEN : textDE}  onChange={(e) => setText(e.target.value)}></textarea></p>
                      <br></br>
                      <span style={{
                        fontWeight: 'bold',
                        color: 'red',
                      }}>{emailError}</span>
                      <p>
                          <button id="submitBtn" className="w3-button theme-color-bg" type="submit">
                          <i className="fa fa-paper-plane" ></i>{language === "en" ? btnEN : btnDE}
                          </button>
                      </p>
                    </form>
                  </div>
              </div>
              </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Contact
