import React, { useState, useEffect } from 'react';

const SlideShowBox = ({images}) => {
    
  const [slideIndex, setSlideIndex] = useState(1);

  const plusSlides = () => {
    let number = slideIndex;
    number++;
    setSlideIndex(number)
    window.setTimeout ( function() {   
      showSlides(number);
    }, 50);
  }

  const minusSlides = () => {
    let number = slideIndex;
    number--;
    setSlideIndex(number)
    window.setTimeout ( function() {   
      showSlides(number);
    }, 50);
  }

  const currentSlide = (n) => {
    setSlideIndex(n);
    showSlides(n);
  }

  const showSlides = (n) => {
    let i;
    let numOfSlides = images.length;
    let number = 0 + n;

    const slides = [];
    for (i = 0; i < numOfSlides; i++) {
        slides.push(document.getElementById("slide01" + i + "-" + 1));
    }

    const dots = [];
    for (i = 0; i < numOfSlides; i++) {
        dots.push(document.getElementById("dot01" + i + "-" + 1));
    }

    if (n > numOfSlides) {
        setSlideIndex(1);
        number = 1;
    }    
    if (n < 1) {
        setSlideIndex(numOfSlides);
        number = numOfSlides;
    }
    for (i = 0; i < numOfSlides; i++) {
        slides[i].style.display = "none";  
    }
    for (i = 0; i < numOfSlides; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
    }
    number--;
    slides[number].style.display = "block";  
    dots[number].className += " active";
  }


  useEffect(() => {
    window.setTimeout ( function() {   
      currentSlide(0); 
      window.setTimeout ( function() {   
        currentSlide(1); 
      }, 100);
    }, 100);

  }, []);

  const makeDots = () => {
    const row = [];
    for (var i = 0; i < images.length; i++) {
      row.push(<span key={i} id={"dot01" + i + "-" + 1} className="dot"></span> );
    }
    return row;
  };

  const makeSlides = () => {
    const row = [];
    for (var i = 0; i < images.length; i++) {
      row.push(<div key={i} id={"slide01" + i + "-" + 1} className="mySlides">
      <img src={images[i]} className="w3-hover-opacity2 shadow-child slide-center" alt="Not found"></img>
    </div> );
    }
    return row;
  };


  const closeBox = (e) => {

    if (e.target instanceof HTMLImageElement || e.target instanceof HTMLAnchorElement)
    {
        return;
    } 
    
    window.setTimeout ( function() {   
        currentSlide(1); 
      }, 40);
    
    window.setTimeout ( function() {  
      document.getElementById("slideshow-box").style.display = "none";
      }, 50);

  }

  return (
    <div onClick={(e) => closeBox(e)} id="slideshow-box">
        <button  onClick={(e) => closeBox(e)} style={{position:"fixed", top:0, right:0, zIndex:"200", height:"60px", width:"60px", fontSize: "30px"}}> X </button>
      <div id="slideshow-box-container">
        <div id="slideshow-box-center" className="w3-container slideshow-box-padding" >

            <a className="prev" style={{paddingLeft: "2%", transform:"scale(1.5)"}} onClick={() => minusSlides()}>❮</a>
            <a className="next" style={{paddingRight: "2%", transform:"scale(1.5)"}} onClick={() => plusSlides()}>❯</a>

            <div>
                <div className="slideshow-container shadow-parent">
                    {
                        makeSlides()
                    }
                </div>
                <br></br>
                <div style={{position: "absolute", textAlign: "center", bottom: "15px", left: "30%", right: "30%"}}>
                    {
                        makeDots()
                    }
                </div>
            </div>
      </div>
      </div>
    </div>
  )
}

export default SlideShowBox
